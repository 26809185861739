import { request } from '@umijs/max';

export const login = async (params, options) => {
  return request('/api/user/login', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const register = async (params, options) => {
  return request('/api/user/register', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};
