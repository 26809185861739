import { connect, history, useIntl, setLocale } from 'umi';
import { Row, Space, Button } from 'antd';
import { useMount } from 'ahooks';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';

import useWindowSize from '@/hooks/useWindowSize';

const HeaderUser = ({ name, nickname, role, dispatch }) => {
  const intl = useIntl();
  const msg = intl.messages;

  const { isMobile } = useWindowSize();

  useMount(() => {
    dispatch({
      type: 'user/startup',
    });
  });

  const isLogin = role !== 'guest';

  const gotoLogin = () => {
    history.push('/user/login');
  };

  const logout = () => {
    dispatch({
      type: 'user/logout',
    });
    history.push('/');
  };

  const switchLanguage = () => {
    const locale = intl.locale === 'en-US' ? 'zh-CN' : 'en-US';
    setLocale(locale, false);
  };

  const langText = () => {
    if (!isMobile) {
      return msg['user.lang']
    } else {
      if (intl.locale === 'en-US') {
        return '中'
      } else {
        return 'EN'
      }
    }

  };

  const logText = () => {
    if (!isMobile) {
      return isLogin ? msg['user.logout'] : msg['user.login']
    } else {
      return null
    }
  };

  return (
    <div
      style={{
        marginRight: isMobile ? -5 : 24,
      }}
    >
      <Row justify="end" algin="middle">
        <Space>
          <Button
            size='small'
            type='default'
            onClick={switchLanguage}
          >
            {langText()}
          </Button>

          {isMobile ? null : <span className="sider-username">{isLogin ? nickname + ' ' : ''}</span>}

          <Button
            size="small"
            type={isLogin ? 'default' : 'primary'}
            onClick={isLogin ? logout : gotoLogin}
            icon={!isMobile ? null : isLogin ? <LogoutOutlined /> : <LoginOutlined />}
          >
            {' '}
            {logText()}
          </Button>
        </Space>
      </Row>
    </div>
  );
};

export default connect(({ user }) => ({
  ...user,
}))(HeaderUser);
