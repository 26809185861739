import { List } from 'antd';
import { history } from '@umijs/max';
import Structure from '@/components/Structure';

const StructGrid = ({ data, onItemClick, size = 150, gutter = 8 }) => {
  return (
    <List
      dataSource={data}
      grid={{
        gutter,
      }}
      renderItem={(item) => {
        const { num } = item;
        return (
          <List.Item>
            <div
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                if (onItemClick) {
                  onItemClick();
                }
                history.push(`/record?id=${item.record_id}`);
              }}
            >
              <Structure struct={item.struct} size={size} />
              <div
                style={{
                  zIndex: 1,
                  position: 'absolute',
                  top: 0,
                  left: 8,
                  fontSize: 10,
                }}
              >
                <span>{num}</span>
              </div>
            </div>
          </List.Item>
        );
      }}
    />
  );
};

export default StructGrid;
