import { request } from '@umijs/max';

export const getRecord = async (params, options) => {
  const url = '/api/record/get_record/' + params.record_id;
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getWorkspaceRecord = async (params, options) => {
  const url = '/api/record/get_workspace_record/' + params.record_id;
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getRecordStruct = async (params, options) => {
  const url = '/api/record/get_record_struct/' + params.record_id;
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getStructHash = async (params, options) => {
  const url = '/api/record/get_struct_hash';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const createRecord = async (params, options) => {
  const url = '/api/record/create_record';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const pushLatest = async (params, options) => {
  const url = '/api/record/push_latest';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};
