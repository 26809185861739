import { request } from '@umijs/max';

export const getStats = async (params, options) => {
  const url = '/api/resource/get_stats';
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getRecords = async (params, options) => {
  const url = '/api/resource/get_records';
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};


export const getDef = async (params, options) => {
  const char = params.char;
  const def = sessionStorage.getItem(char + '-def');
  if (def) {
    return Promise.resolve(def);
  }
  const url = '/api/resource/get_def';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getDefs = async (params, options) => {
  const url = '/api/resource/get_defs';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const definitionSearch = async (params, options) => {
  const url = '/api/resource/definitionSearch';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};
