import React, { useState } from 'react';
import { Row, Input, Modal, List, Button } from 'antd';

import * as fontConst from '@/constants/font';
import * as searchAPI from '@/services/search';
import * as resourceAPI from '@/services/resource';
import * as charUtils from '@/utils/character';
import { Link, useIntl } from '@umijs/max';

import StructureGrid from '@/components/StructureGrid';
import './SearchBar.less';
import Highlighter from "react-highlight-words";

import useWindowSize from '@/hooks/useWindowSize';

const SearchInput = Input.Search;
import { FontFamily } from '@/constants/font';

const StructModal = ({ open,
  char, data, exampleData, textSearchResult, query,
  gutter = 8, size, onCancel }) => {
  const intl = useIntl();
  const msg = intl.messages;

  const { isMobile } = useWindowSize();

  const initResult = Object.keys(exampleData).length > 0 ? [exampleData] : [];
  const result = [...initResult, ...textSearchResult];
  return (
    <Modal
      title={msg['home.structures_in_lib']}
      open={open}
      onCancel={() => onCancel()}
      footer={null}
    >
      <StructureGrid
        data={data}
        size={size}
        gutter={gutter}
        onItemClick={onCancel}
      />
      <Row>
        <Link key="create" to={`/create?char=${char}`}>
          <Button
            // key="create"
            type="primary"
            size='small'
            // href={`/create?char=${char}`}
            onClick={() => onCancel()}
          >
            {/* Not found, create a new record */}
            {msg['home.structure_not_found']}
          </Button>
        </Link>
      </Row>
      <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 6, marginTop: 10 }}>{msg['home.text_search_result']}</div>
      <div style={{
        overflowY: 'scroll',
        maxHeight: 'calc(100vh - 460px)',
        width: '100%',
      }}>
        {result.map((item, index) => (
          <Row key={index} align="middle" wrap style={{ fontFamily: FontFamily }}>
            <Highlighter
              searchWords={[query]}
              autoEscape={true}
              textToHighlight={`${index + 1} ` + item.char + ': ' + item.def}
              highlightStyle={{ backgroundColor: '#ffc069' }}
            />
            {/* <div>
              {`[${index + 1}] `} {item.char}: {item.def}
            </div> */}
          </Row>
        ))}
      </div>
    </Modal>
  );
};

const SearchBar = () => {
  const { isMobile } = useWindowSize();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exampleChar, setExampleChar] = useState('');

  const [data, setData] = useState([]);
  const Size = 120;

  const [exampleData, setExampleData] = useState({});
  const [textSearchResult, setTextSearchResult] = useState([]);
  const [query, setQuery] = useState('');
  const handleHeaderSearch = async (value) => {
    const valid_chars = charUtils.extractUniqueChars(value);

    if (valid_chars.length === 1) {
      setLoading(true);

      const res = await searchAPI.char2records({ char: value })
      setExampleChar(valid_chars[0]);
      setData(res);

      const def = await resourceAPI.getDef({ char: value });
      setExampleData({ char: value, def });

      const textRes = await resourceAPI.definitionSearch({ text: value });
      setTextSearchResult(textRes);

      setQuery(value);

      setModalOpen(true);
      setLoading(false);


    } else {
      setLoading(true);
      const text = value.length > 100 ? value.slice(0, 100) : value;
      const textRes = await resourceAPI.definitionSearch({ text });
      setTextSearchResult(textRes);

      setExampleChar('');
      setExampleData({});

      setQuery(text);

      setModalOpen(true);
      setLoading(false);
    }
  };

  const intl = useIntl();
  const msg = intl.messages;

  const MobileBarWidth = (window.innerWidth - 36 - 78 - 30) * 0.9
  return (
    <Row>
      <SearchInput
        id="HeaderSearchBar"
        onSearch={handleHeaderSearch}
        size={isMobile ? 'middle' : 'large'}
        className="headInput"
        placeholder={msg['home.example_char']}
        loading={loading}
        style={{
          width: isMobile ? MobileBarWidth : 360,
          marginLeft: isMobile ? 20 : 0,
          fontFamily: fontConst.FontFamily,
        }}
      />
      <StructModal
        open={modalOpen}
        title={'Structure'}
        char={exampleChar}
        data={data}
        exampleData={exampleData}
        textSearchResult={textSearchResult}
        query={query}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        size={Size}
      />
    </Row>
  );
};

export default SearchBar;
